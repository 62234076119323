export const QuestionConfig = [
    {
        question: `Is there a deadline to RSVP?`,
        answer: `Please RSVP before April 1, 2025. If you do not RSVP on time, we will assume you are unable to attend`,
    },
    {
        question: `What do I do if I can't make it?`,
        answer: `You will be dearly missed! If you are unable to make it, please let us know and “DECLINE” so we can plan accordingly.`,
    },
    {
        question: `Why doesn't my name appear in the RSVP section?`,
        answer: `The RSVP deadline has now passed; so unfortunately, this means you have missed the deadline. We are really sorry you aren't able to celebrate with us but we have now turned our numbers into our coordinators.`,
    },
    {
        question: `Do you have a registry?`,
        answer: `No, we don't have. We are so grateful for your love and support as we start this new chapter together. Your presence at our wedding is truly the best gift we could ask for! However, for those who feel moved to give, monetary gift is one we suggest.`,
    },
    {
        question: `Can I take photographs on the day?`,
        answer: `We will be having an unplugged ceremony (no mobile phones or cameras during the ceremonial event) so please do not take photos during this time. We have amazing professional photographers and content creators to capture these memories which we will of course share with you. Please feel free to take as many photos as you like before & after the ceremony.`,
    },
    {
        question: `Can I get transportation to and from ShangriLa?`,
        answer: `Our wedding coordinator has arranged transportation for everyone who likes to avail, here's the number: +63 908 378 2505 (Viber).`,
    },
    {
        question: `Can I bring a plus one?`,
        answer: `We are only able to accommodate those listed on the invitation. If you received a plus one invitation, it will appear under your name when your RSVP. Thank you for understanding & please reach out for any questions.`,
    },
    {
        question: `Can I bring my child / children to the wedding?`,
        answer: `We kindly ask that our wedding celebration remain an adults-only event. While we adore your little ones, only children who are part of the ceremony and have received an invitation (such as bearers) are welcome. Thank you for your understanding and for helping us make this day truly special.`,
    },
];

